<template>
    <div style="min-height: 100%;background-color: #EEEEEC;">
        <div class="header">
            <i @click="go('/')" class="el-icon-arrow-left"></i>
            <span>验标</span>
            <span>离线</span>
        </div>
        <div class="card">
            <el-row>
                <el-col :span="18">
                    <div class="title">自动生成生猪档案：</div>
                    <!-- <div class="comment">测试能繁母猪保险，离线待上传0头</div> -->
                    <van-cell-group style="padding: 10px;width:370px;text-align: left;" >
                    <div data-v-28a5ce7f="" class="van-cell">
                        <div data-v-28a5ce7f="" class="van-cell__title">
                            <span  class="big-title">生成ID</span>
                            <!-- <div data-v-28a5ce7f="" class="van-cell__label">描述信息</div> -->
                        </div>
                        <div data-v-28a5ce7f="" class="van-cell__value" style="width: 200px;">
                            <div data-v-28a5ce7f="" style="width: 200px;">1506888374789981243534</div>
                        </div>
                    </div>
                    <div data-v-28a5ce7f="" class="van-cell">
                        <div data-v-28a5ce7f="" class="van-cell__title">
                            <span data-v-28a5ce7f="">采集人</span>
                            <!-- <div data-v-28a5ce7f="" class="van-cell__label">描述信息</div> -->
                        </div>
                        <div data-v-28a5ce7f="" class="van-cell__value">
                            <div data-v-28a5ce7f="">×××</div>
                        </div>
                    </div>
                    <van-cell title="采集地点" value="××省××市李家村" />
                    <van-cell title="所在圈舍" value="第八肥育舍第9排第18位" />
                    <!-- <van-cell title="验标方式" value="智能点数" /> -->
                </van-cell-group>
                </el-col>
                <!-- <el-col :span="6">
                    <div class="next">下一步<i class="el-icon-arrow-right"></i></div>
                </el-col> -->
            </el-row>
        </div>
        <div class="btn-title">已采集头像</div>
        <div class="pig-container">
            <el-row :gutter="20">
                <el-col :span="12" v-for="(val,key) in pigs" :key="key">
                    <div class="pig-box">
                        <i class="el-icon-delete corner"></i>
                        <img :src="val.face" alt="">
                        <div class="info">
                            <div>
                                <span>{{ val.name }}</span>
                                <!-- <span class="id">{{ val.id }}</span> -->
                            </div>
                            <!-- <div class="time">{{ val.date }}</div> -->
                        </div>
                    </div>
                </el-col>
            </el-row>
            
        </div>
        <div class="btn">新增</div>
    </div>
</template>
<script>
export default{
    data() {
        return {
            pigs:[{
                name:'左脸',
                face:require('@/assets/img/图层 1 拷贝 2.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
            {
                name:'右脸',
                face:require('@/assets/img/图层 1 拷贝 3.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
            {
                name:'中脸',
                face:require('@/assets/img/图层 4 拷贝.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            }]
        }
    },
}
</script>
<style lang="stylus" scoped>
.header{
    background-color: #04C694;
    height: 2.5rem;
    line-height: 2.5rem;
    color: #fff;
    display flex;
    justify-content space-between;
    align-items center
    box-sizing border-box;
    padding 0 20px;
    i{
        font-size 26px;
        font-weight 600
    }
}

.pig-container{
    padding: 0 10px
}

.pig-box{
    margin 10px 0;
    position relative;
    background-color #fff;
    padding-bottom 10px;
    img{
        width 100%;
    }

    .info{
        text-align left ;
        padding 0 10px; 
        background-color #04c694
        color #fff;
        text-align center;
        .id{
            float right;
            color #029A64;
        }
        .time{
            font-size 14px;
            margin-top 5px;
        }
    }
}

.btn{
    position fixed;
    z-index 2;
    bottom 50px;
    background-color #04C694;
    color #fff;
    width 70px;
    height 70px;
    line-height 70px;
    border-radius 50%;
    left 50%
    transform translateX(-50%);
    // cursor pointer;
}

.card{
    background-color #fff
    margin 10px;
    border-radius 5px;
    .title{
        font-size 18px;
        border-left 3px solid #04C694;
        text-align left;
        text-indent 5px;
        margin 10px 10px;
    }
    .comment{
        font-size 14px;
        margin-bottom 10px;
        text-align left;
        text-indent 20px;
    }
    .next{
        margin-top 10px;
        color #04C694;
        i{
            font-size 16px;
            font-weight 600;
        }
    }
}

.corner{
    position absolute;
    right 5px;
    top 5px;
    font-size 20px;
    z-index 1;
    color #fff;
}

.btn-title{
    width 100px;
    background-color #04c694;
    margin-left 10px;
    color #fff;
    font-weight 600;
    padding 5px 0;
    border 2px solid #fff;
}
</style>
